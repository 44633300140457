import { Component, OnInit } from '@angular/core';
import { HostListener} from "@angular/core";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  addheaderClass:any;
  constructor() { }

  ngOnInit() {
  }

  @HostListener("window:scroll", [])

  onWindowScroll() {

    var number = document.documentElement.scrollTop;

    console.log(number)
    if(number > 100){
      this.addheaderClass=true;
      //console.log(number)

    }
    else{
      this.addheaderClass=false;
     // console.log("0")

    }

  }

}